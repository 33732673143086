$pink: #FF3866;
$grey: #9B9B9B;
$primaryfont: 'proxima-nova';
$serif: 'elena-web-basic';
$email-font-size: 30px;

body, html {
	font-family: $primaryfont;
	background: #F7F7F7;
	font-size: 16px;
	text-align: center;
	margin: 0px;
	padding: 0;
	height: 100%;
}
.wrapper {
	min-height:100%;
	position:relative;
}

.top {
	float: right;
	margin: 20px 20px 0 0;
}
button.signin {
	border: 2px solid $pink;
	color: $pink;
	min-width: 150px;
	height: 50px;
	background: none;
	border-radius: 25px;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	transition: 0.3s;
	&:hover {
		color: #D73057;
		border-color: #D73057;
	}
}
.content {
	padding: 60px 0 250px 0;
	.logo {
		margin: 50px 0 5px;
		img{
			width: 133px;
			height: auto;
		}
	}
	h1 {
		font-size: 18px;
		font-weight: normal;
		color: $grey;
	}
	h2 {
		font-weight: normal;
		color: $grey;
	}
}
.arrivingsoon {
	p {
		color: $grey;
		margin: 30px 0;
		font-weight: bold;
	}
}

footer {
	height: 100px;
	background: #3D3D3D;
	width:100%;
	position:absolute;
	bottom:0;
	left:0;
	padding: 75px 0 0 0;
	a {
		font-size: 16px;
		font-weight: 600;
		color: $grey;
		transition: 0.3s;
		text-decoration: none;
		&::after {
			content: '/';
			margin: 0 20px;
		}
		&:last-child::after {
			content: none;
		}
		&:hover {
			color: $grey;
		}
	}
}

// MailChimp Overrides

#mailchimp{
	max-width: 650px;
	margin: 0 auto;
	padding-top: 30px;
	.sentence {
		font-family: $serif;
		font-size: 30px;
	}
	input[type="email"], input[type="text"]{
		height: 39px;
		width: 70px;
		font-size: $email-font-size;
		font-family: $serif;
		display: inline;
		color: $grey;
		border: none;
		border-bottom: 1px dashed $grey;
		background: none;
		&:focus {
			outline: none;
		}
	}
	input[type="submit"]{
		height: 40px;
		background: $pink;
		border-radius: 30px;
		min-width: 150px;
		border: none;
		color: white;
		font-size: 16px;
		font-weight: bold;
		padding: 0 20px;
		margin-top: 50px;
		transition: 0.3s;
		cursor: pointer;
		&:hover {
			background: #D73057;
		}
	}

	#mce-EMAIL-hidden {
	  	position: absolute;
	    display: none;
	  	top: 0;
	  	left: 0;
		font-size: $email-font-size;
		font-family: $serif;
	}
}

// Mobile Styles

@media (max-width: 940px) {
	.top {
		float: none;
		margin-right: 0px;
	}
	#content {
		padding: 20px 0 250px 0;
	}
	#mailchimp {
		padding: 30px 15px 0;
		input[type="email"]{
			float:none;
		}
		input[type="submit"]{
			float:none;
			width: 264px;			
			-webkit-appearance: none;
   			appearance: none;
		}
	}
	footer {
		padding: 55px 0 0 0;
		a{
			display: block;
			margin: 5px 0;
			&::after {
				content: none;
	    	}
    	}
	}
}

// Legal Pages

.legal {
	max-width: 700px;
	margin: 0 auto;
	padding: 20px 10px 30px;
	p {
		line-height: 1.2;
		color: $grey;
		font-size: 16px;
		text-align: left;
	}
	h1 {
		color: $pink;
		margin: 30px 0;
	}
	h2 {
		font-size: 18px;
		text-align: left;
	}
}
